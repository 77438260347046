import React, { useEffect, useState } from "react";
import { Statistic } from "antd";

import { ChartCard } from "../../../../components/ChartCard/ChartCard";
import PieChart from "../../../../components/visualizations/PieChartNew";
import cubejs from "../../../../cube";
import { icons } from "../utils";
import i18n from "../../../../i18n";

const colors = ["#2BA6FF", "#CAE9FF", "#FF7324"];
const cubejsApi = cubejs({ appId: 1 });

const InRange = ({ toggleShowModal, regions, districts, range }) => {
  const [pieData, setPieData] = useState([]);
  const [cardData, setCardData] = useState({});

  const commonFilters = [
    {
      dimension: "Appeals.region",
      operator: "contains",
      values: regions,
    },
    {
      dimension: "Appeals.district",
      operator: "contains",
      values: districts,
    },
    {
      dimension: "Appeals.submissionDate",
      operator: "inDateRange",
      values: range,
    },
  ];

  let query = [
    {
      measures: ["Appeals.appealsNum"],
      timeDimensions: [],
      dimensions: ["Appeals.appealType", "Appeals.source"],
      filters: [
        {
          dimension: "Appeals.appealType",
          operator: "contains",
          values: ["Запрос"],
        },
        ...commonFilters,
      ],
      order: {
        "Appeals.source": "asc",
      },
      renewQuery: true,
    },
    {
      measures: ["Appeals.appealsNum"],
      timeDimensions: [],
      dimensions: ["Appeals.appealType", "Appeals.source"],
      filters: [
        {
          dimension: "Appeals.appealType",
          operator: "contains",
          values: ["Жалоба"],
        },
        ...commonFilters,
      ],
      order: {
        "Appeals.source": "asc",
      },
      renewQuery: true,
    },
    {
      measures: ["Appeals.appealsNum"],
      timeDimensions: [],
      dimensions: ["Appeals.appealType", "Appeals.source"],
      filters: [
        {
          dimension: "Appeals.appealType",
          operator: "contains",
          values: ["Заявление"],
        },
        ...commonFilters,
      ],
      order: {
        "Appeals.source": "asc",
      },
      renewQuery: true,
    },
  ];

  const getPieData = async () => {
    let response = await cubejsApi.load({
      measures: ["Appeals.appealsNum"],
      dimensions: ["Appeals.appealType"],
      filters: commonFilters,
      renewQuery: true,
    });

    let resultSet = response.rawData();
    let sum = resultSet.reduce(
      (a, b) => a + Number(b["Appeals.appealsNum"]),
      0
    );
    const data = resultSet.map((e) => ({
      ...e,
      percent: ((+e["Appeals.appealsNum"] / sum) * 100).toFixed(2),
    }));
    setPieData(data);
  };

  const getOtherData = async () => {
    query = query.map((e) => cubejsApi.load(e));
    Promise.all(query).then((r) => {
      setCardData([
        { title: i18n.t("caseNames.informationRequest"), data: r[0].rawData() },
        {
          title: i18n.t("caseNames.incident"),
          data: r[1].rawData(),
        },
        {
          title: i18n.t("caseNames.inquiry"),
          data: r[2].rawData(),
        },
      ]);
    });
  };

  const getData = async () => {
    getPieData();
    getOtherData();
  };

  useEffect(() => {
    let role = JSON.parse(localStorage.getItem("user_params"));
    if (role?.roles?.[0]?.region?.length && regions.length) {
      getData();
    } else if (!role?.roles?.[0]?.region?.length) {
      getData();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regions, districts, range]);

  return (
    <div style={{ flex: 1 }}>
      <ChartCard title={i18n.t("caseNames.forPeriod")}>
        <PieChart
          data={pieData || []}
          loading={false}
          dataKey="Appeals.appealsNum"
          nameKey="Appeals.appealType"
          colorList={colors}
          id="second"
          showTotal
          showLegend={true}
          totalFontSize={16}
          disableMovement
          height="250px"
          exportExcel
        />
      </ChartCard>
      <ChartCard title={i18n.t("caseNames.forPeriod")}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            padding: 15,
          }}
        >
          {cardData[0] &&
            cardData.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "27%",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    borderBottom: "1px solid #40404D",
                    marginBottom: 15,
                  }}
                >
                  <div>
                    <h4 style={{ opacity: "0.6" }}>{item.title}:</h4>
                  </div>
                  <div>
                    <h4>
                      {pieData.find(
                        (e) => e["Appeals.appealType"] === item.title
                      )
                        ? pieData.find(
                            (e) => e["Appeals.appealType"] === item.title
                          )["Appeals.appealsNum"]
                        : 0}
                    </h4>
                  </div>
                </div>
                {item.data.map((event, index) => (
                  <div
                    onClick={() => {
                      toggleShowModal(
                        event["Appeals.source"],
                        item.title,
                        false
                      );
                    }}
                    key={index}
                    className="statistic_card"
                  >
                    <Statistic
                      title={event["Appeals.source"]}
                      value={event["Appeals.appealsNum"]}
                      valueStyle={{ fontSize: "16px" }}
                      groupSeparator="&thinsp;"
                      prefix={
                        <img
                          style={{
                            width: 30,
                            cursor: "pointer",
                          }}
                          alt={event["Appeals.source"]}
                          src={"." + icons[event["Appeals.source"]]}
                        />
                      }
                    />
                  </div>
                ))}
              </div>
            ))}
        </div>
      </ChartCard>
    </div>
  );
};
export default InRange;
